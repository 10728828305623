import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from "../Auth/base.js";

const EditBook = (props) => {
    const navigate = useNavigate(); 
    const db = firebase.firestore();

    const [change, setChange] = useState("");
    const [pause, setPause] = useState(true);

    const [checkedTitle, setCheckedTitle] = useState(false);
    const [checkedLanguage, setCheckedLanguage] = useState(false);
    const [checkedCategories, setCheckedCategories] = useState(false);
    const [checkedAuthors, setCheckedAuthors] = useState(false);
    const [checkedPageCount, setCheckedPageCount] = useState(false);
    const [checkedPublisher, setCheckedPublisher] = useState(false);
    const [checkedPublishedDate, setCheckedPublishedDate] = useState(false);
    const [checkedPublishedLocation, setCheckedPublishedLocation] = useState(false);
    const [checkedPublishedNumber, setCheckedPublishedNumber] = useState(false);
    const [checkedSeries, setCheckedSeries] = useState(false);
    const [checkedSize, setCheckedSize] = useState(false);
    const [checkedBinding, setCheckedBinding] = useState(false);
    const [checkedCondition, setCheckedCondition] = useState(false);
    const [checkedOther, setCheckedOther] = useState(false);
    const [checkedPrice, setCheckedPrice] = useState(false);
    const [checkedLocation, setCheckedLocation] = useState(false);

    const [ISBN, setISBN] = useState("");
    const [base64, setBase64] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [pageCount, setPageCount] = useState("");
    const [categories, setCategories] = useState("");
    const [authors, setAuthors] = useState("");
    const [publisher, setPublisher] = useState("");
    const [publishedDate, setPublishedDate] = useState("");
    const [publishedNumber, setPublishedNumber] = useState("");
    const [publishedLocation, setPublishedLocation] = useState("");
    const [series, setSeries] = useState("");
    const [binding, setBinding] = useState("");
    const [condition, setCondition] = useState("");
    const [size, setSize] = useState("");
    const [other, setOther] = useState("");
    const [price, setPrice] = useState("");
    const [location, setLocation] = useState("");

    const [newTitle, setNewTitle] = useState("");
    const [newLanguage, setNewLanguage] = useState("");
    const [newPageCount, setNewPageCount] = useState("");
    const [newCategories, setNewCategories] = useState("");
    const [newAuthors, setNewAuthors] = useState("");
    const [newPublisher, setNewPublisher] = useState("");
    const [newPublishedDate, setNewPublishedDate] = useState("");
    const [newPublishedNumber, setNewPublishedNumber] = useState("");
    const [newPublishedLocation, setNewPublishedLocation] = useState("");
    const [newSeries, setNewSeries] = useState("");
    const [newBinding, setNewBinding] = useState("");
    const [newCondition, setNewCondition] = useState("");
    const [newSize, setNewSize] = useState("");
    const [newWidth, setNewWidth] = useState("");
    const [newHeight, setNewHeight] = useState("");
    const [newOther, setNewOther] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const [newLocation, setNewLocation] = useState("");
    
    const [publisherArray, setPublisherArray] = useState([]);
    const [publishedLocationArray, setPublishedLocationArray] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [seriesArray, setSeriesArray] = useState([]);

    useEffect(() => {
        if (props.auth !== "authenticated") {
            navigate('/login');
        }
        if (!props.book) {
            navigate('/admin');
        }
        if (change !== props.book)
        {
            db.collection("books").where("isbn","==", props.book)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.empty) {
                    alert("NO BOOK FOUND");
                    navigate("/admin");
                } 
                else {
                    docSnapshot.forEach(function(doc) {
                        setISBN(doc.data().isbn)
                        setTitle(doc.data().title);
                        setAuthors(doc.data().authors);
                        setLanguage(doc.data().language);
                        setPageCount(doc.data().pageCount);
                        setCategories(doc.data().categories);
                        setPublisher(doc.data().publisher);
                        setPublishedDate(doc.data().publishedDate);
                        setBase64(doc.data().base64);
                        setPublishedNumber(doc.data().publishedNumber);
                        setPublishedLocation(doc.data().publishedLocation);
                        setSeries(doc.data().series);
                        setBinding(doc.data().binding);
                        setCondition(doc.data().condition);
                        setSize(doc.data().size);
                        setOther(doc.data().other);
                        setPrice(doc.data().price);
                        setLocation(doc.data().location);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            setChange(props.book);

            //GET PUBLISHER LIST
            db.collection("publishers").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setPublisherArray(oldArray => [...oldArray, doc.data().publisher]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET PUBLISHED LOCATION LIST
            db.collection("published_location").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setPublishedLocationArray(oldArray => [...oldArray, doc.data().publishedLocation]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET CATEGORIES LIST
            db.collection("categories").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setCategoriesArray(oldArray => [...oldArray, doc.data().categories]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET SERIES LIST
            db.collection("series").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setSeriesArray(oldArray => [...oldArray, doc.data().series]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        }
    }, [navigate, change, props, db, base64, size]);

    async function setProperties(){
        if (!newTitle) {
            setNewTitle(title);
        }
        if (newCategories === "" && !checkedCategories) {
            setNewCategories(categories);
        }
        if (newBinding === "") {
            setNewBinding(binding);
        }
        if (newOther === "" && !checkedOther) {
            setNewOther(other);
        }
        if (newSeries === "" && !checkedSeries) {
            setNewSeries(series);
        }
        if (newPrice === "") {
            setNewPrice(price);
        }
        if (newCondition === "") {
            setNewCondition(condition);
        }
        if (newAuthors === "") {
            setNewAuthors(authors);
        }
        if (newPublisher === "") {
            setNewPublisher(publisher);
        }
        if (newLocation === "") {
            setNewLocation(location);
        }
        if (newLanguage === "") {
            setNewLanguage(language);
        }
        if (newPublishedDate === ""  && !checkedPublishedDate) {
            setNewPublishedDate(publishedDate);
        }
        if (newPublishedNumber === ""  && !checkedPublishedNumber) {
            setNewPublishedNumber(publishedNumber);
        }
        if (newPublishedLocation === ""  && !checkedPublishedLocation) {
            setNewPublishedLocation(publishedLocation);
        }
        if (newWidth === "" || newHeight === "") {
            setNewSize(size);
        }
        else{
            setNewSize(newWidth + " cm x " + newHeight + " cm");
        }
        if (newPageCount === "") {
            setNewPageCount(pageCount);
        }
        else if (!newPageCount.includes("oldal")){
            setNewPageCount(newPageCount + " oldal");
        }
        setPause(!pause);
        return;
    }

    function editBook(e){
        e.preventDefault();
        e.stopPropagation(); 
        e.nativeEvent.stopImmediatePropagation();
        
        const booksDB = db.collection("books").doc(props.book);
        const publishersDB = db.collection("publishers");
        const publishedLocationDB = db.collection("published_location");
        const categoriesDB = db.collection("categories");
        const seriesDB = db.collection("series");

        if (pause) {
            alert("TRY AGAIN SUBMITTING");
        } else {            
            //ADD NEW PUBLISHER TO DB
            if (!publisherArray.includes(newPublisher)) {
                publishersDB.add({
                    publisher: publisher,
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }
            //ADD NEW PUBLISHED LOCATION TO DB
            if (!publishedLocationArray.includes(newPublishedLocation)) {
                publishedLocationDB.add({
                    publishedLocation: publishedLocation,
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }
            //ADD NEW CATEGORIES TO DB
            if (!categoriesArray.includes(newCategories)) {
                categoriesDB.add({
                    categories: categories,
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }
            //ADD NEW SERIES TO DB
            if (!seriesArray.includes(newSeries)) {
                seriesDB.add({
                    series: series,
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }

            //EDIT BOOK
            booksDB.get()
            .then(() => {
                if (base64 === ""){
                    alert("ERROR - NO BASE64");
                }
                else if (props.book === ""){
                    alert("ERROR - NO ISBN");
                }
                else {
                    booksDB.set({
                        isbn: ISBN,
                        base64: base64,
                        title: newTitle,
                        authors: newAuthors,
                        language: newLanguage,
                        pageCount: newPageCount,
                        categories: newCategories,
                        publisher: newPublisher,
                        publishedDate: newPublishedDate,
                        publishedNumber: newPublishedNumber,
                        publishedLocation: newPublishedLocation,
                        series: newSeries,
                        binding: newBinding,
                        condition: newCondition,
                        size: newSize,
                        other: newOther,
                        price: newPrice,
                        location: newLocation,
                    }).then(() => {
                        alert("BOOK SUCCESFULLY EDITED!");
                        props.liftBase64Up(base64);
                    }).then(() => {
                        navigate('/admin');
                    });
                }
            })
        }
    }

    return (
        <div>
            <h1>Könyvszerkesztés felület</h1>
            <form onSubmit={e => editBook(e)}>
                <div>
                    <img height="300px" src={base64} alt={title} />
                </div> 
                <h2>ISBN: {ISBN}</h2>
                <div> {/* BOOK PROPS */}
                    <div><b>Cím: </b>
                        <input type="text" readOnly value={title}/>
                        <input type="checkbox" onChange={() => setCheckedTitle(!checkedTitle)}/>
                        {checkedTitle
                        ? <input required type="text" onChange={e=>setNewTitle(e.target.value)} placeholder="Új cím:"/>
                        : <></>}
                    </div> 
                    <div><b>Nyelv: </b>
                        <input type="text" readOnly value={language}/>
                        <input type="checkbox" onChange={() => setCheckedLanguage(!checkedLanguage)}/>
                        {checkedLanguage
                        ? <input required type="text" onChange={e=>setNewLanguage(e.target.value)} placeholder="Új nyelv:"/>
                        : <></>}
                    </div>
                    <div><b>Kategóriák: </b>
                        <input type="text" readOnly value={categories}/>
                        <input type="checkbox" onChange={() => setCheckedCategories(!checkedCategories)}/>
                        {checkedCategories
                        ? <span><input type="search" list="categories" onChange={e=>setNewCategories(e.target.value)} placeholder="Új kategória:"/>
                          <datalist id="categories">
                            {categoriesArray.map(val => <option value={val} key={val}>{val}</option>)}
                          </datalist><br/></span>
                        : <></>}
                    </div>
                    <div><b>Írók: </b>
                        <input type="text" readOnly value={authors}/>
                        <input type="checkbox" onChange={() => setCheckedAuthors(!checkedAuthors)}/>
                        {checkedAuthors
                        ? <input required type="text" onChange={e=>setNewAuthors(e.target.value)} placeholder="Új írók:"/>
                        : <></>}
                    </div>
                    <div><b>Lapszám: </b>
                        <input type="text" readOnly value={pageCount}/>
                        <input type="checkbox" onChange={() => setCheckedPageCount(!checkedPageCount)}/>
                        {checkedPageCount
                        ? <input required type="text" onChange={e=>setNewPageCount(e.target.value)} placeholder="Új oldalszám:"/>
                        : <></>}
                    </div>
                    <div><b>Kiadó: </b>
                        <input type="text" readOnly value={publisher}/>
                        <input type="checkbox" onChange={() => setCheckedPublisher(!checkedPublisher)}/>
                        {checkedPublisher
                        ? <span><input required type="search" list="publisher" onChange={e=>setNewPublisher(e.target.value)} placeholder="Új kiadó:"/>
                          <datalist id="publisher">
                            {publisherArray.map(val => <option value={val} key={val}>{val}</option>)}
                          </datalist><br/></span>
                        : <></>}
                    </div>
                    <div><b>Kiadás éve: </b>
                        <input type="text" readOnly value={publishedDate}/>
                        <input type="checkbox" onChange={() => setCheckedPublishedDate(!checkedPublishedDate)}/>
                        {checkedPublishedDate
                        ? <input required type="text" onChange={e=>setNewPublishedDate(e.target.value)} placeholder="Új kiadás dátuma:"/>
                        : <></>}
                    </div><br/>
                    <div><b>Kiadás helye: </b>
                        <input type="text" readOnly value={publishedLocation}/>
                        <input type="checkbox" onChange={() => setCheckedPublishedLocation(!checkedPublishedLocation)}/>
                        {checkedPublishedLocation
                        ? <span><input type="search" list="publishedLocation" onChange={e=>setNewPublishedLocation(e.target.value)} placeholder="Kiadás helye:"/>
                          <datalist id="publishedLocation">
                            {publishedLocationArray.map(val => <option value={val} key={val}>{val}</option>)}
                          </datalist></span>
                        : <></>}
                    </div>
                    <div><b>Kiadás száma: </b>
                        <input type="text" readOnly value={publishedNumber}/>
                        <input type="checkbox" onChange={() => setCheckedPublishedNumber(!checkedPublishedNumber)}/>
                        {checkedPublishedNumber
                        ? <input type="text" onChange={e=>setNewPublishedNumber(e.target.value)} placeholder="Kiadás száma:"/>
                        : <></>}
                    </div>
                    <div><b>Sorozat címe: </b>
                        <input type="text" readOnly value={series}/>
                        <input type="checkbox" onChange={() => setCheckedSeries(!checkedSeries)}/>
                        {checkedSeries
                        ? <span><input type="search" list="series" onChange={e=>setNewSeries(e.target.value)} placeholder="Sorozat címe:"/>
                          <datalist id="series">
                            {seriesArray.map(val => <option value={val} key={val}>{val}</option>)}
                          </datalist></span>
                        : <></>}
                    </div>
                    <div><b>Méret: </b>
                        <input type="text" readOnly value={size}/>
                        <input type="checkbox" onChange={() => setCheckedSize(!checkedSize)}/>
                        {checkedSize
                        ? <span><input required type="text" onChange={e=>setNewWidth(e.target.value)} placeholder="Szélesség:"/>
                          <input required type="text" onChange={e=>setNewHeight(e.target.value)} placeholder="Magasság:"/></span>
                        : <></>}
                    </div><br/>
                    <div><b>Kötés: </b>
                        <input type="text" readOnly value={binding}/>
                        <input type="checkbox" onChange={() => setCheckedBinding(!checkedBinding)}/>
                        {checkedBinding
                        ? <span>
                            <input required type="radio" value="Papír" name="Binding" onChange={e=>setNewBinding(e.target.value)}/>
                                  <label htmlFor="Papír">Papír</label>
                            <input required type="radio" value="Kemény" name="Binding" onChange={e=>setNewBinding(e.target.value)}/>
                                  <label htmlFor="Kemény">Kemény</label>
                          </span>
                        : <></>}
                    </div>
                    <div><b>Állapot: </b>
                        <input type="text" readOnly value={condition}/>
                        <input type="checkbox" onChange={() => setCheckedCondition(!checkedCondition)}/>
                        {checkedCondition
                        ? <span>
                            <input required type="radio" value="Jó" name="Condition" onChange={e=>setNewCondition(e.target.value)}/>
                                <label htmlFor="Jó">Jó</label>
                            <input required type="radio" value="Használt" name="Condition" onChange={e=>setNewCondition(e.target.value)}/>
                                <label htmlFor="Használt">Használt</label>
                            <input required type="radio" value="Viseltes" name="Condition" onChange={e=>setNewCondition(e.target.value)}/>
                                <label htmlFor="Viseltes">Viseltes</label>
                          </span>
                        : <></>}
                    </div>
                    <div><b>Egyéb: </b>
                        <input type="text" readOnly value={other}/>
                        <input type="checkbox" onChange={() => setCheckedOther(!checkedOther)}/>
                        {checkedOther
                        ? <input type="text" onChange={e=>setNewOther(e.target.value)} placeholder="Egyéb:"/>
                        : <></>}
                    </div>
                </div><br/>
                <div> {/* OWN PURPOSE */}
                    <div><b>Ár: </b>
                        <input type="text" readOnly value={price}/>
                        <input type="checkbox" onChange={() => setCheckedPrice(!checkedPrice)}/>
                        {checkedPrice
                        ? <input required type="text" onChange={e=>setNewPrice(e.target.value)} placeholder="Ár:"/>
                        : <></>}
                    </div>
                    <div><b>Hely: </b>
                        <input type="text" readOnly value={location}/>
                        <input type="checkbox" onChange={() => setCheckedLocation(!checkedLocation)}/>
                        {checkedLocation
                        ? <input required type="text" onChange={e=>setNewLocation(e.target.value)} placeholder="Hely:"/>
                        : <></>}
                    </div>
                </div><br/>
                {checkedTitle || checkedLanguage || checkedCategories || checkedAuthors || checkedPageCount || checkedPublisher 
                || checkedPublishedDate || checkedPublishedLocation || checkedPublishedNumber || checkedSeries || checkedSize 
                || checkedBinding || checkedCondition || checkedOther || checkedPrice || checkedLocation 
                ? <button type="submit" className="editBookButton" onClick={(e) => setProperties(e)}>
                    Könyv módosítása
                  </button>
                : <Link to="/admin">
                    <button>
                        Vissza
                    </button>
                  </Link>}
            </form>
        </div>
    )
}

export default EditBook