import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import firebase from "../Auth/base.js";

const AddBook = (props) => {
    const navigate = useNavigate(); 
    const db = firebase.firestore();

    const [result, setResult] = useState([]);
    const [change, setChange] = useState("");
    const apiKey = "AIzaSyDhBuAZEUcZEtVvXEOdKDGcsZe_MQ6j7Ng";

    const [checkedTitle, setCheckedTitle] = useState(false);
    const [checkedLanguage, setCheckedLanguage] = useState(false);
    const [checkedCategories, setCheckedCategories] = useState(false);
    const [checkedAuthors, setCheckedAuthors] = useState(false);
    const [checkedPageCount, setCheckedPageCount] = useState(false);
    const [checkedPublisher, setCheckedPublisher] = useState(false);
    const [checkedPublishedDate, setCheckedPublishedDate] = useState(false);

    const [base64, setBase64] = useState("");
    const [title, setTitle] = useState("");
    const [language, setLanguage] = useState("");
    const [pageCount, setPageCount] = useState("");
    const [categories, setCategories] = useState("");
    const [authors, setAuthors] = useState("");
    const [publisher, setPublisher] = useState("");
    const [publishedDate, setPublishedDate] = useState("");
    const [publishedNumber, setPublishedNumber] = useState("");
    const [publishedLocation, setPublishedLocation] = useState("");
    const [series, setSeries] = useState("");
    const [binding, setBinding] = useState("");
    const [condition, setCondition] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [other, setOther] = useState("");
    const [price, setPrice] = useState("");
    const [location, setLocation] = useState("");

    const [publisherArray, setPublisherArray] = useState([]);
    const [publishedLocationArray, setPublishedLocationArray] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [seriesArray, setSeriesArray] = useState([]);

    useEffect(() => {
        if (props.auth !== "authenticated") {
            navigate('/login');
        }
        if (!props.book) {
            navigate('/admin');
        }
        if (change !== props.book)
        {
            //GET PICTURE OF ISBN
            db.settings({
                timestampsInSnapshots: true,
                merge: true,
            });
            db.collection("pics").where("isbn","==", props.book)
            .get()
            .then((docSnapshot) => {
                if (docSnapshot.empty) {
                    navigate("/camera");
                }
                else {
                    docSnapshot.forEach(function(doc) {
                        setBase64(doc.data().base64);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            
            setChange(props.book);

            //GOOGLE API CALL
            axios.get("https://www.googleapis.com/books/v1/volumes?q=isbn:"+props.book+"&key="+apiKey)
            .then(data => {
                if (data.data.totalItems !== 0) {
                    setResult(data.data.items);
                }
            })
            .catch(function(error) {
                alert("Error getting documents: ", error);
            })

            //GET PUBLISHER LIST
            db.collection("publishers").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setPublisherArray(oldArray => [...oldArray, doc.data().publisher]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET PUBLISHED LOCATION LIST
            db.collection("published_location").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setPublishedLocationArray(oldArray => [...oldArray, doc.data().publishedLocation]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET CATEGORIES LIST
            db.collection("categories").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setCategoriesArray(oldArray => [...oldArray, doc.data().categories]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
            //GET SERIES LIST
            db.collection("series").get()
            .then((docSnapshot) => {
                if (!docSnapshot.empty){
                    docSnapshot.forEach(function(doc) {
                        setSeriesArray(oldArray => [...oldArray, doc.data().series]);
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        }
    }, [navigate, props, change, db, base64, result]);
    
    function addBook(e){
        e.preventDefault();
        e.stopPropagation(); 
        e.nativeEvent.stopImmediatePropagation();

        const booksDB = db.collection("books").doc(props.book);
        const picsDB = db.collection("pics").doc(props.book);
        const publishersDB = db.collection("publishers");
        const publishedLocationDB = db.collection("published_location");
        const categoriesDB = db.collection("categories");
        const seriesDB = db.collection("series");

        //ADD NEW PUBLISHER TO DB
        if (!publisherArray.includes(publisher)) {
            publishersDB.add({
                publisher: publisher,
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
        //ADD NEW PUBLISHED LOCATION TO DB
        if (!publishedLocationArray.includes(publishedLocation)) {
            publishedLocationDB.add({
                publishedLocation: publishedLocation,
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
        //ADD NEW CATEGORIES TO DB
        if (!categoriesArray.includes(categories)) {
            categoriesDB.add({
                categories: categories,
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
        //ADD NEW SERIES TO DB
        if (!seriesArray.includes(series)) {
            seriesDB.add({
                series: series,
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
        }

        //ADD BOOK TO DB
        booksDB.get()
        .then((docSnapshot) => {
            if (docSnapshot.exists) {
                alert("ISBN ALREADY EXIST!");
            }
            else if (base64 === ""){
                alert("ERROR - NO BASE64");
            }
            else if (props.book === ""){
                alert("ERROR - NO ISBN");
            }
            else {
                booksDB.set({
                    title: title,
                    isbn: props.book,
                    authors: authors,
                    language: language,
                    pageCount: pageCount + " oldal",
                    categories: categories,
                    publisher: publisher,
                    publishedDate: publishedDate,
                    base64: base64,
                    publishedNumber: publishedNumber,
                    publishedLocation: publishedLocation,
                    series: series,
                    binding: binding,
                    condition: condition,
                    size: width + " cm x " + height + " cm",
                    other: other,
                    price: price,
                    location: location,
                }).then(() => {
                    alert("BOOK SUCCESFULLY ADDED!");
                    picsDB.delete();
                    props.liftBase64Up(base64);
                }).then(() => {
                    navigate('/admin');
                });
            }
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    }
    if (props.book === "") {
        return <h1>Loa.. Wait for it... ding! LOADING</h1>;
    }
    else{
        return (
            <div>
                <h1>Add Book Form</h1>
                <form onSubmit={e => addBook(e)}>
                    <div>
                        <img height="300px" src={base64} alt={result[0]?.volumeInfo?.title} />
                    </div> 
                    <div> {/* BOOK PROPS */}
                        <div><b>Cím<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.title !== undefined ? <div><input required type="text" readOnly value={result[0]?.volumeInfo.title}/>
                                                                   <input type="checkbox" onChange={() => setCheckedTitle(!checkedTitle)}/></div> 
                                                                   : <input required type="text" onChange={e=>setTitle(e.target.value)} placeholder="Új cím:"/>}
                            {checkedTitle
                            ? <input required type="text" onChange={e=>setTitle(e.target.value)} placeholder="Új cím:"/>
                            : (title === "" || title !== result[0]?.volumeInfo.title) && result[0]?.volumeInfo?.title
                                ? setTitle(result[0]?.volumeInfo.title) 
                                : <></>}
                        </div> 
                        <div><b>Nyelv<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.language !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.language || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedLanguage(!checkedLanguage)}/></div>
                                                                   : <input required type="text" onChange={e=>setLanguage(e.target.value)} placeholder="Új nyelv:"/>}
                            {checkedLanguage
                            ? <input required type="text" onChange={e=>setLanguage(e.target.value)} placeholder="Új nyelv:"/>
                            : (language === "" || language !== result[0]?.volumeInfo.language) && result[0]?.volumeInfo?.language
                                ? setLanguage(result[0]?.volumeInfo.language) 
                                : <></>}
                        </div>
                        <div><b>Kategóriák: </b>
                            {result[0]?.volumeInfo?.categories !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.categories || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedCategories(!checkedCategories)}/></div>
                                                                   : <span><input type="search" list="categories" onChange={e=>setCategories(e.target.value)} placeholder="Új kategória:"/>
                                                                     <datalist id="categories">
                                                                        {categoriesArray.map(val => <option value={val} key={val}>{val}</option>)}
                                                                     </datalist><br/></span>}
                            {checkedCategories
                            ?   <div><input type="search" list="categories" onChange={e=>setCategories(e.target.value)} placeholder="Új kategória:"/>
                                <datalist id="categories">
                                   {categoriesArray.map(val => <option value={val} key={val}>{val}</option>)}
                                </datalist><br/></div>
                            :   (categories === "" || categories !== result[0]?.volumeInfo.categories) && result[0]?.volumeInfo?.categories
                                    ? setCategories(result[0]?.volumeInfo.categories) 
                                    : <></>}
                        </div>
                        <div><b>Írók<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.authors !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.authors || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedAuthors(!checkedAuthors)}/></div>
                                                                   : <input required type="text" onChange={e=>setAuthors(e.target.value)} placeholder="Új írók:"/>}
                            {checkedAuthors
                            ? <input required type="text" onChange={e=>setAuthors(e.target.value)} placeholder="Új írók:"/>
                            : (authors === "" || authors !== result[0]?.volumeInfo.authors) && result[0]?.volumeInfo?.authors
                                ? setAuthors(result[0]?.volumeInfo.authors) 
                                : <></>}
                        </div>
                        <div><b>Lapszám<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.pageCount !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.pageCount || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedPageCount(!checkedPageCount)}/></div>
                                                                   : <input required type="text" onChange={e=>setPageCount(e.target.value)} placeholder="Új oldalszám:"/>}
                            {checkedPageCount
                            ? <input required type="text" onChange={e=>setPageCount(e.target.value)} placeholder="Új oldalszám:"/>
                            : (pageCount === "" || pageCount !== result[0]?.volumeInfo.pageCount) && result[0]?.volumeInfo?.pageCount
                                ? setPageCount(result[0]?.volumeInfo.pageCount) 
                                : <></>}
                        </div>
                        <div><b>Kiadó<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.publisher !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.publisher || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedPublisher(!checkedPublisher)}/></div>
                                                                   : <span><input required type="search" list="publisher" onChange={e=>setPublisher(e.target.value)} placeholder="Új kiadó:"/>
                                                                     <datalist id="publisher">
                                                                        {publisherArray.map(val => <option value={val} key={val}>{val}</option>)}
                                                                     </datalist><br/></span>}
                            {checkedPublisher
                            ?   <div><input type="search" list="publisher" onChange={e=>setPublisher(e.target.value)} placeholder="Új kiadó:"/>
                                <datalist id="publisher">
                                    {publisherArray.map(val => <option value={val} key={val}>{val}</option>)}
                                </datalist><br/></div>
                            :   (publisher === "" || publisher !== result[0]?.volumeInfo.publisher) && result[0]?.volumeInfo?.publisher
                                ? setPublisher(result[0]?.volumeInfo.publisher) 
                                : <></>}
                        </div>
                        <div><b>Kiadás éve<b style={{color:"red"}}>*</b>: </b>
                            {result[0]?.volumeInfo?.publishedDate !== undefined ? <div><input type="text" readOnly value={result[0]?.volumeInfo.publishedDate || ''}/>
                                                                   <input type="checkbox" onChange={() => setCheckedPublishedDate(!checkedPublishedDate)}/></div>
                                                                   : <input required type="text" onChange={e=>setPublishedDate(e.target.value)} placeholder="Új kiadás dátuma:"/>}
                            {checkedPublishedDate
                            ? <input required type="text" onChange={e=>setPublishedDate(e.target.value)} placeholder="Új kiadás dátuma:"/>
                            : (publishedDate === "" || publishedDate !== result[0]?.volumeInfo.publishedDate) && result[0]?.volumeInfo?.publishedDate
                                ? setPublishedDate(result[0]?.volumeInfo.publishedDate) 
                                : <></>}
                        </div><br/>
                        <div><b>Kiadás helye: </b>
                            <input type="search" list="publishedLocation" onChange={e=>setPublishedLocation(e.target.value)} placeholder="Kiadás helye:"/>
                            <datalist id="publishedLocation">
                                {publishedLocationArray.map(val => <option value={val} key={val}>{val}</option>)}
                            </datalist><br/>
                        </div>
                        <div><b>Kiadás száma: </b>
                            <input type="text" onChange={e=>setPublishedNumber(e.target.value)} placeholder="Kiadás száma:"/>
                        </div>
                        <div><b>Sorozat címe: </b>
                            <input type="search" list="series" onChange={e=>setSeries(e.target.value)} placeholder="Sorozat címe:"/>
                            <datalist id="series">
                                {seriesArray.map(val => <option value={val} key={val}>{val}</option>)}
                            </datalist><br/>
                        </div>
                        <div><b>Méret<b style={{color:"red"}}>*</b>: </b>
                            <input required type="text" onChange={e=>setWidth(e.target.value)} placeholder="Szélesség:"/>
                            <input required type="text" onChange={e=>setHeight(e.target.value)} placeholder="Magasság:"/>
                        </div><br/>
                        <div><b>Kötés<b style={{color:"red"}}>*</b>: </b>
                            <input required type="radio" value="Papír" name="Binding" onChange={e=>setBinding(e.target.value)}/>
                            <label htmlFor="Papír">Papír</label>
                            <input required type="radio" value="Kemény" name="Binding" onChange={e=>setBinding(e.target.value)}/>
                            <label htmlFor="Kemény">Kemény</label>
                        </div>
                        <div><b>Állapot<b style={{color:"red"}}>*</b>: </b>
                            <input required type="radio" value="Jó" name="Condition" onChange={e=>setCondition(e.target.value)}/>
                            <label htmlFor="Jó">Jó</label>
                            <input required type="radio" value="Használt" name="Condition" onChange={e=>setCondition(e.target.value)}/>
                            <label htmlFor="Használt">Használt</label>
                            <input required type="radio" value="Viseltes" name="Condition" onChange={e=>setCondition(e.target.value)}/>
                            <label htmlFor="Viseltes">Viseltes</label>
                        </div>
                        <div><b>Egyéb: </b>
                            <input type="text" onChange={e=>setOther(e.target.value)} placeholder="Egyéb:"/>
                        </div>
                    </div><br/>
                    <div> {/* OWN PURPOSE */}
                        <div><b>Ár<b style={{color:"red"}}>*</b>: </b>
                            <input required type="text" onChange={e=>setPrice(e.target.value)} placeholder="Ár:"/>
                        </div>
                        <div><b>Hely<b style={{color:"red"}}>*</b>: </b>
                            <input required type="text" onChange={e=>setLocation(e.target.value)} placeholder="Hely:"/>
                        </div>
                    </div><br/>
                    <button type="submit" className="addBookButton">
                        Könyv hozzáadása
                    </button><br/>
                </form>
            </div>
        );
    }
};

export default AddBook;