import React, { useEffect } from 'react';
import {Link, useNavigate} from "react-router-dom";

const Admin = (props) => {
    const navigate = useNavigate(); 

    useEffect(() => { 
        if (props.auth !== "authenticated") {
            navigate('/login');
        }
        if (props.base64 !== "") {
            props.liftBase64Up("");
            Reload();
        }
    }, [navigate, props]);

    function Reload() {
        window.location.reload();
    }
    
    function handleChange(event){
        const book = event.target.value;
		props.liftBookUp(book);
    }

    return (
        <div>
            <h2>Admin felület</h2>
            {/* {<h1 className="blink"><b>UPDATE:</b></h1>}
            <h2>Update:</h2>
            <span>Ha minden igaz működik a könyvszerkesztő.
                <br/>Lejjebb van a kereső, ugyanúgy működik mint 
                <br/>a sima form esetében kb. ISBN-t beírod,
                <br/>kiadja a felvitt adatot és ha módosítottál 
                <br/>bármit is akkor alul Vissza gomb helyett 
                <br/>Módosítás gomb jelenik meg.
                <br/><b>Ha szerkesztetek valamit, keressetek rá mégegyszer, 
                <br/>hogy biztos minden adat jó-e!!!</b></span><br/><br/>
            <h4>Könyv szerkesztése/megtekintése:</h4> */}
            <form>
                <input className='adminSearchBar'
                    type="text"
                    onChange={handleChange}
                    placeholder="ISBN"
                /><br/>
                <Link to="/editbook">
                    <button className='adminSearchButton'>Könyv keresése</button><br/>
                </Link>
            </form>
            <h4>Segítség:</h4>
            <span>1. Írd be az ISBN-t a fejlécben lévő keresőbe</span><br/>
            <span>2. Nyomd meg a keresés gombot</span><br/>
            <span>  - Készíts képet ha még nem tetted</span><br/>
            <span>  - Ha már készítettél töltsd ki a formot</span><br/><br/>
            <br/><span>9637914064</span><br/>
            <span>9780006479888</span><br/>
            <span>11111111111111</span><br/>
        </div>
    );
};

export default Admin;
