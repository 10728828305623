import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCIs6dkgITB-n30nHQzf5nfwz23_kq82u0",
  authDomain: "bookshop-361f5.firebaseapp.com",
  projectId: "bookshop-361f5",
  storageBucket: "bookshop-361f5.appspot.com",
  messagingSenderId: "200998379494",
  appId: "1:200998379494:web:4c8ce922fe301fba270027",
  measurementId: "G-E36QFT5FF6"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app;