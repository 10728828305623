import React from 'react';

const Basket = () => {
  return(
  <div>
      <h1>Kosár oldal</h1>
  </div>
  );
};

export default Basket;
