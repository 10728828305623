import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import firebase from "./Auth/base.js";

class Camera extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imageDataURL: null,
      cameraNumber: 0,
      email: null,
    };
    this.setCameraNumber();
  }

  initializeMedia = async () => {
    this.setState({ imageDataURL: null });

    if (!("mediaDevices" in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(new Error("getUserMedia Not Implemented"));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    //Get the details of video inputs of the device
    const videoInputs = await this.getListOfVideoInputs();

    //The device has a camera
    if (videoInputs.length) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: videoInputs[this.state.cameraNumber].deviceId,
            },
          },
        })
        .then((stream) => {
          this.player.srcObject = stream;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("The device does not have a camera");
    }
  };

  capturePicture = () => {
    this.authListener();
    var canvas = document.createElement("canvas");
    canvas.width = this.player.videoWidth;
    canvas.height = this.player.videoHeight;
    var contex = canvas.getContext("2d");
    contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
    this.player.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
    });
    
    this.setState({ imageDataURL: canvas.toDataURL()});
    this.props.liftBase64Up(canvas.toDataURL()); //get base64
  };

  switchCamera = async () => {
    const listOfVideoInputs = await this.getListOfVideoInputs();

    // The device has more than one camera
    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {
        this.player.srcObject.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }
      //console.log(this.state.cameraNumber);
      // Restart based on camera input
      this.initializeMedia();
    } else if (listOfVideoInputs.length === 1) {
      alert("The device has only one camera");
    } else {
      alert("The device does not have a camera");
    }
  };

  getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };

  authListener() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({email: user.email});
    });
  }
  
  setCameraNumber = async () => {
    await this.authListener();
    const db = await firebase.firestore();
    await db.collection("admin_options").where("email","==", this.state.email)
    .get()
    .then((docSnapshot) => {
      if (docSnapshot.empty) {
        alert("NO USER FOUND");
      } 
      else {
        docSnapshot.forEach((doc) => {
          this.setState({ cameraNumber: doc.data().cameraNumber });
        })
      }
    })
    return this.switchCamera();;
  };

  //ADD TO BOOK PIC DB
  addPicture = async () => {
    //console.log(this.state.cameraNumber);
    if (this.state.imageDataURL === null) {
      alert("NO PICTURE TAKEN");
      return;
    }
    const db = firebase.firestore();
    const bookPicDB = db.collection("pics").doc(this.props.book);
    bookPicDB.get()
    .then((docSnapshot) => {
      if (docSnapshot.exists) {
        alert("ISBN ALREADY EXIST!");
      } 
      else {
        db.collection("pics").doc(this.props.book).set({
          isbn: this.props.book,
          base64: this.state.imageDataURL,
        }).then(() => {
          alert("PICTURE SUCCESFULLY ADDED!");
          this.props.navigate("/admin");
        });
      }
    });
  }

  render() {
    if (this.props.auth !== "authenticated") {
      return <Navigate to='/login'/>;
    } 
    if (!this.props.book) {
      return <Navigate to='/admin'/>;
    }
    else {
      const playerORImage = Boolean(this.state.imageDataURL) ? (
        <img src={this.state.imageDataURL} alt="cameraPic" />
      ) : (
        <video
          ref={(refrence) => {
            this.player = refrence;
          }}
          autoPlay
        ></video>
      );
      return (
        <div className="Camera">
          <div className="cameraVideo">
            {playerORImage}
          </div>
          {/*this.initializeMedia*/}
          <br/>
          {/* <button onClick={this.setCameraNumber}>INIT</button> */}
          <div className="cameraButtonDiv">
            <button className="capturePictureButton" onClick={this.capturePicture}>Fénykép készítése</button><br/>
            <button className="addToPicDBButton" type="submit" onClick={this.addPicture}>Adatbázishoz adás</button>
          </div>
        </div>
      );
    }
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Camera {...props} navigate={navigate} />
}

export default WithNavigate;