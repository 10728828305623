import './App.css';
import { useContext, useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/Header.js';
import Login from './Components/Pages/Login.js';
import Admin from './Components/Pages/Admin.js';
import AddBook from './Components/Pages/AddBook.js';
import EditBook from './Components/Pages/EditBook.js';
import Home from './Components/Pages/Home.js';
import Basket from './Components/Pages/Basket.js';
import Search from './Components/Pages/Search.js';
import { Auth, AuthContext } from "./Components/Auth/Auth.js";
import Camera from "./Components/Camera.js";

function App() {
    const {currentUser} = useContext(AuthContext);
    const [auth, setAuth] = useState("");

    const [book, setBook] = useState("");
    const [base64, setBase64] = useState("");

    useEffect(() => { 
      if (!currentUser) {
        setAuth("");
      } else {
        setAuth("authenticated");
      }
    }, [currentUser]);

    function liftBookUp (book) {
      setBook(book);
    }
    function liftBase64Up (base64) {
      setBase64(base64);
    }

    return(
		<Auth>
      <Router>
        <Header liftBookUp={liftBookUp}/>
				<Routes>
					<Route exact path="/" element={ <Home/> }/>
					<Route exact path="/kosar" element={ <Basket/> } />
					<Route exact path="/talalatok" element={ <Search/> } />

					<Route exact path="/login" element={ <Login auth={auth}/> } />
					<Route exact path="/admin" element={ <Admin auth={auth} base64={base64} liftBase64Up={liftBase64Up} liftBookUp={liftBookUp}/> } />
					<Route exact path="/camera" element={ <Camera auth={auth} book={book} liftBase64Up={liftBase64Up}/> } />
					<Route exact path="/addbook" element={ <AddBook auth={auth} book={book} base64={base64} liftBase64Up={liftBase64Up}/>} />
					<Route exact path="/editbook" element={ <EditBook auth={auth} book={book} liftBase64Up={liftBase64Up}/>} />
				</Routes>
      </Router>
		</Auth>
	);
}

export default App;