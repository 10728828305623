import React, { useCallback, useEffect } from 'react';
import app from "../Auth/base.js";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const navigate = useNavigate();

    useEffect(() => { 
        if (props.auth === "authenticated") {
            navigate(-1);
        }
    }, [navigate, props]);

    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
            } catch (error) {
                alert(error);
            }
        }, []
    );

    return (
        <div>
            Login oldal
            <form onSubmit={handleLogin}>
                <label>
                    Email
                    <input name="email" type="email" placeholder='Email' />
                </label>
                <label>
                    Password
                    <input name="password" type="password" placeholder='Password' />
                </label>
                <button type='submit'>Login</button>
            </form>
        </div>
    );
};

export default Login;
