import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Search = () => {
    const location = useLocation();
    const book = location.state;
    
    const [result, setResult] = useState([]);
    const [change, setChange] = useState("");
    const apiKey = "AIzaSyDhBuAZEUcZEtVvXEOdKDGcsZe_MQ6j7Ng";

    function handleSubmit(){
        if (change !== book)
        {
            setChange(book);
            
            axios.get("https://www.googleapis.com/books/v1/volumes?q=isbn:"+book+"&key="+apiKey)
            .then(data => {
                console.log(data.data.items);

                if (data.data.totalItems === 0) {
                    console.log("Nincs találat");
                } else {
                    setResult(data.data.items);                    
                }
            })
        }
    }

    return (
        <div>
            {handleSubmit()}
            <h1>
                Találatok oldal
            </h1>
            <br/>
            <img height="300px" src = {result.length && result[0].volumeInfo?.imageLinks?.thumbnail} alt={result[0]?.volumeInfo?.title}/>
            <h3>Cím: {result[0]?.volumeInfo.title}</h3>
            <h3>Nyelv: {result[0]?.volumeInfo.language}</h3>
            <h3>Kategóriák: {result[0]?.volumeInfo.categories}</h3>
            <h3>Írók: {result[0]?.volumeInfo.authors}</h3>
            <h3>Oldalak száma: {result[0]?.volumeInfo.pageCount}</h3>
        </div>
    );
};

export default Search;
