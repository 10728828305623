import { Link } from 'react-router-dom';
import logo from '../Pics/logo.png';
//import basket_logo from '../Pics/basket.png';
import React, { useState, useContext } from 'react';
import app from "./Auth/base.js";
import { AuthContext } from './Auth/Auth.js';

const Header = (props) => {
	const {currentUser} = useContext(AuthContext);
    const [book, setBook] = useState("");
	
    const handleLogout = () => {
        app.auth().signOut();
    }

    function handleChange(event){
        const book = event.target.value;
		setBook(book);
		props.liftBookUp(book);
    }

	if (!currentUser) {
		return (
			<header className="AppHeader">
				<div className="logo">
					<Link to="/">
						<img src={logo} alt="BookShop" height="30px"></img>
					</Link>
				</div>
				<form className="searchForm">
					<div className="searchBar">
						<input
							type="text"
							onChange={handleChange}
							placeholder="Keresés"
							size="35"
						/>
					</div>
					<Link to="/talalatok" state={ book }>
						<button type="submit" className="searchButton">
							Keresés
						</button>
					</Link>
				</form>
				{/* <div className="basket">
					<Link to="/kosar">
						<img src={basket_logo} alt="Kosár" height="30px"></img>
					</Link>
				</div> */}
				<div>
					<Link to="/login">
						<button>Belépés</button>
					</Link>
				</div>
			</header>
		);
	} else {
		return (
			<header className="AdminHeader">
				<div className="logo">
					<img src={logo} alt="BookShop" height="30px"></img>
				</div>
				<form className="adminSearchForm">
					{/* <div className="adminSearchBar"> */}
						<input
							className="adminSearchBar"
							type="text"
							onChange={handleChange}
							placeholder="ISBN"
							size="35"
						/>
					<Link to="/addbook" state={ book }>
						<button type="submit" className="adminSearchButton">
							Keresés
						</button>
					</Link>
				</form>
				<div className="logout">
					<Link to="/login">
						<button onClick={handleLogout} className="logoutButton">
							Kilépés
						</button>
					</Link>
				</div>
			</header>
		);
	}
};

export default Header;